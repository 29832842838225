<template>
  <div class="login-wrap">
    <div class="login-logo">
      <img src="../../assets/images/logins.png" alt />
    </div>
    <div class="ms-login animate__animated animate__flip">
      <div class="ms-title">用户登录</div>
      <el-form :model="loginForm" label-width="0px" class="ms-content">
        <el-form-item>
          <el-input
            v-model="loginForm.account"
            prefix-icon="el-icon-s-custom"
            placeholder="请输入您的用户名"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="loginForm.password"
            type="password"
            prefix-icon="el-icon-lock"
            placeholder="请输入您的密码"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item prop="code">
          <el-input placeholder="验证码" v-model="loginForm.code" size="small">
            <el-button slot="prepend" class="el-icon-key" ></el-button>
            <template slot="append" >
              <div class='code-svg' v-html='codeSvg' @click='getCodeSvg'></div>
            </template>
          </el-input>
        </el-form-item> -->

        <el-form-item prop="code">
          <div style="display: flex; align-items: center;">
            <el-input placeholder="验证码" v-model="inputCode"  class="inputbox">
            <el-button slot="prepend" class="el-icon-key" ></el-button>
           
          </el-input>
          <div @click="refreshCode()" class='code-svg'>
              <!--验证码组件-->
              <SecurityCode :identifyCode="identifyCode"></SecurityCode>
            </div>
          </div>
       
        </el-form-item>

        <!-- <el-form-item
          prop="code"
          label="验证码"
          class="row"
       
        >
          <div class="row space-between" style="width: 50px">
            <el-input
              type="text"
              style="width: 190px"
              v-model="inputCode"
              autocomplete="off"
            ></el-input>
            <div @click="refreshCode()" style="line-height: 0">
         
              <SecurityCode :identifyCode="identifyCode"></SecurityCode>
            </div>
          </div>
        </el-form-item> -->

        <div class="login-btn">
          <el-button type="primary" @click="handleLogin" size="mini"
            >登录</el-button
          >
        </div>
      </el-form>
    </div>

    <div class="footer-beian">
      成都科达光电技术有限责任公司所有
      <a href="https://beian.miit.gov.cn"> 蜀ICP备2022003944号-1</a>
    </div>
  </div>
</template>

<script>
import logins from "../../api/modules/login";
import SecurityCode from "../../components/securityCode/securityCode.vue";

export default {
  components: {//注册
			SecurityCode
		},
  name: "loginIndex",
  data() {
    return {
      loginForm: {
        account: "",
        password: "",
       
      },
      param: {
        name: "",
        password: "",
        code: 1,
        codeText: "",
      },
      codeSvg: "",
      identifyCode: "",//随机组合字符串
			inputCode: "", //text框输入的验证码
    };
  },
  methods: {
    refreshCode() {
				this.inputCode = ''
				this.identifyCode = ""; //输入框置空
				this.makeCode(4); //验证码长度为4
			},
			//随机切换验证码
			makeCode(length) {
        let code = '';
    for (let i = 0; i < length; i++) {
        const r = Math.floor(Math.random() * 10); // Generate random number between 0 and 9
        code += r;
    }
    //字母加数字
    // for (let i = 0; i < length; i++) {
		// 			const r = Math.floor(Math.random() * 36);
		// 			if (r < 10) {
		// 				code += r;
		// 			} else {
		// 				code += String.fromCharCode(r - 10 + 65);
		// 			}
		// 		}
    this.identifyCode = code;
    // localStorage.setItem('code', code);
    console.log(this.identifyCode);
			},


    getCodeSvg() {
      logins.getCode().then((res) => {
      
        this.codeSvg = res.data.code;
        this.loginForm.uuid = res.data.uuid;
      });
    },
    handleLogin() {
      console.log(this.inputCode,'this.inputCode');
      console.log(this.identifyCode,'this.identifyCode');
      if(this.identifyCode!==this.inputCode){
        this.$message({
            message: "验证码错误",
            type: "warning",
          });
          return
      }
      logins.login(this.loginForm).then((res) => {
        let data = res.data;
        if (!data.staffRole || data.staffRole.length <= 0) {
          this.$message({
            message: "用户未授权，请联系管理人员授权",
            type: "error",
          });
          return;
        }
        // 存储信息入store中
        this.$store.dispatch("common/setLoginUserInfo", data);
        this.$router.push({ path: "/projectScreen" });
        // if (data.orgaLevel >= 3) {
        //   // 项目级进入大屏，
        //   this.$router.push("/screenProject");
        // } else {
        //   this.$router.push({ path: "/projectScreen" });
        // }
      });
    },
  },
  mounted() {
    this.refreshCode()
    // this.getCodeSvg();
    // 实现按下回车键后登录
    document.onkeydown = () => {
      let key = window.event.keyCode;
      if (key === 13) {
        this.handleLogin();
      }
    };
  },
  destroyed() {
    document.onkeydown = null;
  },
};
</script>

// 样式，scoped属性，当前组件页生效
<style lang="less" scoped>
.footer-beian {
  position: absolute;
  bottom: 20px;
  font-size: 13px;
  color: #fff;
}
.footer-beian a,
.footer-beian a:hover,
.footer-beian a:active {
  color: #fff;
}
.login-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url("../../assets/images/login_bg.jpg") no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-form-item {
  margin-bottom: 20px !important;
}

.el-form-item__error {
  margin-top: 4px !important;
}

.el-form-item__content {
  line-height: 32px !important;
}

.ms-title {
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: #fff;
  border-bottom: 1px solid #ddd;
}

.ms-login {
  width: 350px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  overflow: hidden;
  margin-left: 30px;
}

.ms-content {
  padding: 30px 30px;
}

.login-btn {
  text-align: center;
  margin-top: 20px;
}

.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}

.login-tips {
  font-size: 12px;
  line-height: 30px;
  color: #fff;
}
.vaild-code-item .el-form-item__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.vaild-code-wrap {
  background: #f1f1f1;
  border-radius: 4px;
  height: 32px;

  position: relative;
  z-index: 1000;
  top: 0px;
}
.code-svg {
  height: 40px;
  width: 80px;
  // text-align: center;
  line-height: 30px;
}

.code-svg svg {
  height: 100%;
  width: 100%;
}
.space-between{
  display: flex;
}
.inputbox{
  width: 180px;
  margin-right: 10px;
}
</style>
